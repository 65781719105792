body .chaport-container .chaport-launcher .chaport-launcher-button {
  --color: hsl(0, 0%, 0%);
  --size: 5.125rem;
  --icon-size: 2.375rem;

  width: var(--size);
  height: var(--size);
  cursor: pointer;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: hsl(41deg, 100%, 56%) !important;
}

.chaport-launcher-button:hover {
  background-color: hsl(41deg, 100%, 56%) !important;
}

body .chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-chat-icon {
  width: var(--icon-size);
  height: var(--icon-size);

  margin: 0.7rem calc((var(--size) - var(--icon-size)) / 4);
  background-image: url('./chaport-icon.svg');
}

body .chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-close-icon::before,
body .chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-close-icon::after {
  /* width: var(--icon-size); */
  height: var(--icon-size);
  left: 0.3rem;
  top: 0.4rem;
  background-color: #000;
}

body
  .chaport-container.chaport-expanded
  .chaport-launcher
  .chaport-launcher-button
  .chaport-launcher-close-icon::before,
body
  .chaport-container.chaport-expanded
  .chaport-launcher
  .chaport-launcher-button
  .chaport-launcher-close-icon::after {
  left: 0.45rem;
  top: 1.4rem;
}
